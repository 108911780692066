.button {
    font-size: 32px;
    padding: 16px;
    margin: 16px;
    cursor: pointer;
    transition: opacity 0.5s ease, background-color 0.3s ease, color 0.3s ease;
    border: 2px solid #f8fafc;
    background-color: transparent;
    color: #f8fafc;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 600;
    display: inline-block;
}

.button:hover {
  
    opacity: 0.8;
    background-color: #3490dc;
    color: #f8fafc;
}

.nick {
    color: #f8fafc;
    font-size: 220px;
}

.button-container {
    
    display: flex;
    gap: 100px; /* Adjust the gap as needed */
    justify-content: center;
    
}


text-center{
    text-align: center;
}