.App {
    text-align: center;
    margin-top: 100px;
}

.countdown {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    top: 50%;
}

.title {
    font-size: 144px;
}
.countdown-item {
    display: flex;
    padding: 15px;
    /* border-radius: 2px; */
    /* border-color: red; */
    /* border-style: solid; */

    align-items: center;
}

.countdown-value {
    font-size: 72px;
}

.countdown-label {
    position: relative;
    font-size: 36px;
}
